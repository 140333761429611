import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout/Layout';
import styles from './about.module.scss';

import srcLogoWhite from '../assets/logo-white.svg';
import srcLogotypeLVeryLightBlue from '../assets/logotype-very-light-blue.svg';

import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import srcNo1Icon1 from '../assets/about/no1/no1-icon-1.svg';
import srcNo1Icon2 from '../assets/about/no1/no1-icon-2.svg';
import srcNo1Icon3 from '../assets/about/no1/no1-icon-3.svg';
import srcNo1Icon5 from '../assets/about/no1/no1-icon-5.svg';
import srcNo1Icon6 from '../assets/about/no1/no1-icon-6.svg';
import { StripType } from '../types';

interface Props {
  data: {
    contentful: {
      teamCollection: { items: Team[] };
      teamMemberCollection: { items: TeamMember[] };
      stripCollection: { items: StripType[] };
    };
  };
}

export interface TeamMember {
  sys: {
    id: string;
  };
  name: string;
  englishName: string;
  profilePicture: {
    url: string;
    width: number;
    height: number;
  };
  title: string;
  description: {
    json: Document;
    links: any;
  };
  experience: {
    json: Document;
    links: any;
  };
}

export interface Team {
  sys: {
    id: string;
  };
  name: string;
  slug: string;
  membersCollection: {
    items: [
      {
        sys: {
          id: string;
        };
      }
    ];
  };
}

export interface TeamMap {
  [index: string]: {
    name: string;
    slug: string;
    members: TeamMember[];
  };
}

const normalizeTeams = ({
  teams,
  teamMembers,
}: {
  teams: Team[];
  teamMembers: TeamMember[];
}): TeamMap => {
  return teams.reduce<TeamMap>((teamMap, team) => {
    teamMap[team.slug] = {
      name: team.name,
      slug: team.name,
      members: team.membersCollection.items.reduce<TeamMember[]>(
        (members, member) => {
          if (!member) {
            return members;
          }

          const found = teamMembers.find(
            (item: TeamMember) => item.sys.id === member.sys.id
          );

          if (found) {
            members.push(found);
          }

          return members;
        },
        []
      ),
    };

    return teamMap;
  }, {});
};

const renederOptions = {
  renderText: (text: string) =>
    text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
};

const toggleSingleBio = (bioStates: boolean[], index: number) => {
  const dup = [...bioStates];

  dup[index] = !dup[index];

  return dup;
};

export const aboutPageQuery = graphql`
  query AboutPageQuery($language: String!, $dev: Boolean!) {
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
      teamCollection(preview: $dev) {
        items {
          sys {
            id
          }
          name(locale: $language)
          slug
          membersCollection {
            items {
              sys {
                id
              }
            }
          }
        }
      }
      teamMemberCollection(preview: $dev) {
        items {
          sys {
            id
          }
          englishName
          name
          profilePicture {
            url
            width
            height
          }
          title(locale: $language)
          description(locale: $language) {
            json
          }
          experience(locale: $language) {
            json
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const AboutPage = ({ data }: Props) => {
  const { t, originalPath, i18n } = useI18next();
  const {
    contentful: {
      stripCollection: { items: strips },
      teamCollection: { items: teams },
      teamMemberCollection: { items: teamMembers },
    },
  } = data;
  const teamMap = normalizeTeams({
    teams,
    teamMembers,
  });
  const [activeBios, setActiveBios] = useState(
    new Array(teamMap['executive-leadership'].members.length).fill(true)
  );

  useEffect(() => {
    setActiveBios([...activeBios].fill(false));
  }, []);

  return (
    <Layout t={t} originalPath={originalPath} i18n={i18n} strips={strips}>
      <section className={styles.hero}>
        <img
          src={srcLogotypeLVeryLightBlue}
          alt=""
          className={styles.heroBgLogotype}
        />
        <div className={styles.wrapper}>
          <h1>
            <Trans i18nKey="about-page-title" />
          </h1>
          <blockquote className={styles.quote}>
            <p>{t('about-page-slogan')}</p>
            <cite>{t('about-page-slogan-by')}</cite>
          </blockquote>
          <p>
            <Trans
              i18nKey="about-page-company-desc"
              components={{ b: <b /> }}
            />
          </p>
        </div>
      </section>
      <section className={styles.no1Container}>
        <div className={styles.no1}>
          <div className={styles.box}>
            <header>
              <b className={styles.no1Block}>
                <span className={styles.inJapan}>
                  {t('about-page-no1-in-japan')}
                </span>
                <span className={styles.number}>1</span>
                <span className={styles.st}>{t('about-page-no1-st')}</span>
              </b>
              <div className={styles.desc}>
                <Trans
                  i18nKey="about-page-no1-desc"
                  components={{ b: <b /> }}
                />
              </div>
            </header>
            <div className={styles.features}>
              <ol>
                <li>
                  <h3>
                    <img src={srcNo1Icon1} alt="" width="24" height="24" />
                    {t('about-page-no1-1-title')}
                  </h3>
                  <p>{t('about-page-no1-1-desc')}</p>
                </li>
                <li>
                  <h3>
                    <img src={srcNo1Icon2} alt="" width="24" height="24" />
                    {t('about-page-no1-2-title')}
                  </h3>
                  <p>{t('about-page-no1-2-desc')}</p>
                </li>
                <li>
                  <h3>
                    <img src={srcNo1Icon3} alt="" width="24" height="24" />
                    {t('about-page-no1-3-title')}
                  </h3>
                  <p>{t('about-page-no1-3-desc')}</p>
                </li>
                {/* <li>
                  <h3>
                    <img src={srcNo1Icon4} alt="" width="24" height="24" />
                    {t('about-page-no1-4-title')}
                  </h3>
                  <p>{t('about-page-no1-4-desc')}</p>
                </li> */}
                <li>
                  <h3>
                    <img src={srcNo1Icon5} alt="" width="24" height="24" />
                    {t('about-page-no1-5-title')}
                  </h3>
                  <p>{t('about-page-no1-5-desc')}</p>
                </li>
                <li>
                  <h3>
                    <img src={srcNo1Icon6} alt="" width="24" height="24" />
                    {t('about-page-no1-6-title')}
                  </h3>
                  <p>{t('about-page-no1-6-desc')}</p>
                </li>
                <li></li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.missionContainer}>
        <article className={styles.mission}>
          <div className={styles.box}>
            <h2>
              <img src={srcLogoWhite} alt="" width="70" height="80" />
              {t('about-page-our-mission')}
            </h2>
            <div className={styles.content}>
              <div className={styles.left}>
                <Trans i18nKey="about-page-our-mission-1" />
              </div>
              <div className={styles.right}>
                <Trans i18nKey="about-page-our-mission-2" />
              </div>
            </div>
          </div>
        </article>
      </section>
      <section className={styles.teamContainer}>
        <div className={styles.team}>
          <div className={styles.box}>
            <h2>{t('about-page-team')}</h2>
            <h3>{teamMap['executive-leadership'].name}</h3>
            <ul className={styles.members}>
              {teamMap['executive-leadership'].members.map((member, index) => {
                return (
                  <li key={index}>
                    {member.profilePicture ? (
                      <img
                        src={member.profilePicture.url}
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <div className={styles.avatar}></div>
                    )}
                    <h3>
                      {member.englishName}
                      <span>{member.name}</span>
                    </h3>
                    <h4>{member.title}</h4>
                    <div
                      className={cx(
                        styles.bio,
                        activeBios[index] && styles.active
                      )}
                    >
                      {member.description &&
                        documentToReactComponents(
                          member.description.json,
                          renederOptions
                        )}
                      {member.experience &&
                        documentToReactComponents(
                          member.experience.json,
                          renederOptions
                        )}
                    </div>
                    <button
                      className={styles.bioButton}
                      onClick={useCallback(() => {
                        setActiveBios(toggleSingleBio(activeBios, index));
                      }, [activeBios])}
                    >
                      {activeBios[index]
                        ? t('about-page-hide-bio')
                        : t('about-page-see-bio')}
                    </button>
                  </li>
                );
              })}
            </ul>

            <h2>{t('about-page-team')}</h2>
            <h3>{teamMap['cross-functional'].name}</h3>
            <ul className={styles.members}>
              {teamMap['cross-functional'].members.map((member, index) => {
                return (
                  <li key={index}>
                    {member.profilePicture ? (
                      <img
                        src={member.profilePicture.url}
                        alt=""
                        loading="lazy"
                      />
                    ) : (
                      <div className={styles.avatar}></div>
                    )}
                    <h3>
                      {member.englishName}
                      <span>{member.name}</span>
                    </h3>
                    <h4>{member.title}</h4>
                    <div>
                      {member.experience &&
                        documentToReactComponents(
                          member.experience.json,
                          renederOptions
                        )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
      <section className={styles.companyContainer}>
        <div className={styles.company}>
          <div className={styles.box}>
            <h2>{t('about-page-company-profile-title')}</h2>
            <main>
              <div className={styles.row}>
                <h4>{t('company-name-title')}</h4>
                <p>{t('company-name-detail')}</p>
              </div>
              <div className={styles.row}>
                <h4>{t('company-established-title')}</h4>
                <p>{t('company-established-at')}</p>
              </div>
              <div className={styles.row}>
                <h4>CEO</h4>
                <p>{t('company-ceo-sam')}</p>
              </div>
              <div className={styles.row}>
                <h4>{t('company-business-type-title')}</h4>
                <p>{t('company-business-type')}</p>
              </div>
              <div className={styles.row}>
                <h4>{t('company-office-title')}</h4>
                <p>
                  <Trans i18nKey="company-office-location" />
                </p>
              </div>
            </main>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;
